<template>
	<spares-form />
</template>
<script>
import SparesForm from '@/components/inventory/spares/SparesForm/SparesForm.vue';

export default {
	name: 'CreateSparesPage',
	components: { SparesForm },
};
</script>
